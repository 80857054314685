import { bindable, inject }             from 'aurelia-framework';
import { I18N }                         from 'aurelia-i18n';
import { activationStrategy }           from 'aurelia-router';
import { BaseOrderedListViewModel }     from 'base-ordered-list-view-model';
import { FilterFormSchema }             from 'modules/management/dynamic-fields/association-sectors/filter-form-schema';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';
import { AssociationSectorsRepository } from './services/repository';

@inject(AppContainer, AssociationSectorsRepository, I18N, Downloader, FilterFormSchema)
export class ListAssociationSectors extends BaseOrderedListViewModel {

    listingId = 'management-association-sectors-listing';

    category = 'in_situ';

    @bindable headerTitle = 'listing.management.dynamic-fields.association-sectors.in-situ';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        this.category = params.category;

        this.headerTitle = 'listing.management.dynamic-fields.association-sectors.' + params.category;

        return super.can([
            'management.dynamic-fields.association-sectors.manage',
            'management.dynamic-fields.association-sectors.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search: (criteria) => this.repository.search({ category: this.category, ...criteria }),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.dynamic-fields.association-sectors.edit', { category: this.category, id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.association-sectors.manage', 'management.dynamic-fields.association-sectors.edit']),
            },
            options:         [],
            buttons:         [],
            selectable:      false,
            destroySelected: false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'association_sector_translations.name',
                    title: 'form.field.sector',
                },
                {
                    data:  'updated_by',
                    name:  'users.name',
                    title: 'form.field.updated-by',
                },
                {
                    data:            'updated_at',
                    name:            'association_sectors.updated_at',
                    title:           'form.field.updated-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}

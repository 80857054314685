import { inject }                         from 'aurelia-framework';
import { UsagesRepository }         from 'modules/management/aggregates/usages/services/repository';
import { ManageLayersRepository }         from 'modules/management/dynamic-fields/manage-layers/services/repository';
import { PartZoneApplicationsRepository } from 'modules/management/dynamic-fields/part-zone-applications/services/repository';
import { PerformedTestsRepository }       from 'modules/management/dynamic-fields/performed-tests/services/repository';
import { SampleCollectionsRepository }    from 'modules/management/dynamic-fields/sample-collections/services/repository';
import { AssociationSectorField }         from 'modules/management/models/association-sector-field';

@inject(PerformedTestsRepository, SampleCollectionsRepository, PartZoneApplicationsRepository, ManageLayersRepository, UsagesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param testsRepository
     * @param samplesRepository
     * @param pzaRepository
     * @param layersRepository
     * @param usagesRepository
     */
    constructor(testsRepository, samplesRepository, pzaRepository, layersRepository, usagesRepository) {
        this.testsRepository   = testsRepository;
        this.samplesRepository = samplesRepository;
        this.pzaRepository     = pzaRepository;
        this.layersRepository  = layersRepository;
        this.usagesRepository  = usagesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AssociationSectorField}
     */
    model() {
        let model = new AssociationSectorField();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {

        this.test_id = {
            type:         'select2',
            key:          'test_id',
            id:          'test_id_' + viewModel.slug + viewModel.rand,
            label:        'form.field.performed-test',
            remoteSource: () => this.testsRepository.all(),
            required:     true,
            size:         4,
        };

        this.sample_id = {
            type:         'select2',
            key:          'sample_id',
            id:          'sample_id_' + viewModel.slug + viewModel.rand,
            label:        'form.field.sample-collection',
            remoteSource: () => this.samplesRepository.all(),
            required:     true,
            size:         4,
        };

        this.pza_id = {
            type:         'select2',
            key:          'pza_id',
            id:          'pza_id_' + viewModel.slug + viewModel.rand,
            label:        'form.field.part-zone-application',
            remoteSource: () => this.pzaRepository.all(),
            required:     true,
            size:         4,
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            id:          'layer_id_' + viewModel.slug + viewModel.rand,
            label:        'form.field.layer',
            remoteSource: () => this.layersRepository.all(),
            required:     true,
            size:         4,
        }

        this.usage_id = {
            type:         'select2',
            key:          'usage_id',
            id:          'usage_id_' + viewModel.slug + viewModel.rand,
            label:        'form.field.usage',
            remoteSource: () => this.usagesRepository.all(),
            required:     true,
            size:         4,
        }

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     (event) => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:       'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions:    [
                this.closeButton,
                this.submitButton,
            ],
        };

        switch (viewModel.slug) {
            case viewModel.sector.inSituWorkControl:
                return [
                    [this.test_id, this.pza_id, this.layer_id],
                    [this.buttons],
                ];
            case viewModel.sector.labWorkControl:
                return [
                    [this.sample_id, this.pza_id, this.layer_id],
                    [this.buttons],
                ];
            case viewModel.sector.labUseIn:
                return [
                    [this.sample_id, this.pza_id, this.usage_id],
                    [this.buttons],
                ];
            case viewModel.sector.labMaterialCharacterization:
                this.sample_id.size = 12
                return [
                    [this.sample_id],
                    [this.buttons],
                ];
        }

    }

}

import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class FieldsRepository extends BaseRepository {
    baseUrl = 'management/dynamic-fields/association-sectors/fields';
    associationSectorsUrl = 'management/dynamic-fields/association-sectors';

    setSectorId(sectorId) {
        this.baseUrl = this.associationSectorsUrl + `/${sectorId}/fields`;
    }
}

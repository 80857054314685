import { inject }                         from 'aurelia-framework';
import { ManageLayersRepository }         from 'modules/management/dynamic-fields/manage-layers/services/repository';
import { PartZoneApplicationsRepository } from 'modules/management/dynamic-fields/part-zone-applications/services/repository';
import { PerformedTestsRepository }       from 'modules/management/dynamic-fields/performed-tests/services/repository';
import { AssociationSector }              from 'modules/management/models/association-sector';

@inject(PerformedTestsRepository, PartZoneApplicationsRepository, ManageLayersRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param testsRepository
     * @param pzaRepository
     * @param layersRepository
     */
    constructor(testsRepository, pzaRepository, layersRepository) {
        this.testsRepository  = testsRepository;
        this.pzaRepository    = pzaRepository;
        this.layersRepository = layersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AssociationSector}
     */
    model() {
        let model = new AssociationSector();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        //this.test_id = {
        //    type:         'select2',
        //    key:          'test_id',
        //    label:        'form.field.performed-test',
        //    remoteSource: () => this.testsRepository.all(),
        //    required:     false,
        //    size:         4,
        //    attributes:   {
        //        readonly: readonly,
        //    },
        //};
        //
        //this.pza_id = {
        //    type:         'select2',
        //    key:          'pza_id',
        //    label:        'form.field.part-zone-application',
        //    remoteSource: () => this.pzaRepository.all(),
        //    required:     false,
        //    size:         4,
        //    attributes:   {
        //        readonly: readonly,
        //    },
        //};
        //
        //this.layer_id = {
        //    type:         'select2',
        //    key:          'layer_id',
        //    label:        'form.field.layer',
        //    remoteSource: () => this.layersRepository.all(),
        //    required:     false,
        //    size:         4,
        //    attributes:   {
        //        readonly: readonly,
        //    },
        //};

        const schema = [
            //[this.test_id, this.pza_id, this.layer_id],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute({
                name:   'management.dynamic-fields.association-sectors.index',
                params: {
                    category: viewModel.category,
                },
            }),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
